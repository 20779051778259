import React from 'react';


const ScrollButton = () => {
  return (
    <a href="#section-2" id="scroll-btn"></a>
  );
};

export default ScrollButton;
