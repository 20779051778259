import React from 'react'

const Footer = () => {
  return (
    <footer>

<div className='footer-statement-container'>
            <h3 className='footer-statement'>Take control of the internship searching process and navigate its lack of transparency.</h3>
        </div>
    </footer>
  )
}

export default Footer